import { Link } from 'react-router-dom';
import { MenuDropdown } from './MenuDropdown';

export const Header = () => {
  return (
    <header className="container py-4 flex justify-between items-center">
      <Link to="/">
        <img width={120} height="auto" src="/logo.png" alt="logo" />
      </Link>

      <div>
        <MenuDropdown />
      </div>
    </header>
  );
};
