import React, { useEffect, useState } from 'react';
import {
  getEntrypoints,
  deleteEntrypoint
} from '~/Entrypoints/entrypoints-api';
import {
  EntrypointData,
  FetchEntrypointsResponse
} from '~/Entrypoints/entrypoints-types';
import {
  EntrypointTable,
  EntrypointContent
} from '@/components/Table/EntrypointTable';

import styles from './style.module.css';
import { CreateEntryPointModal, AddMetaDataModal } from '@/components/Modal';

export const Entrypoints: React.FC = () => {
  const [entrypoints, setEntrypoints] = useState<
    FetchEntrypointsResponse | undefined
  >(undefined);
  const [reloadState, setReloadState] = useState<string>('1');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [entrypointsAdaptedForCardGrid, setEntrypointsAdaptedForCardGrid] =
    useState<EntrypointContent[]>();
  const [copyableEntrypointUrl, setCopyableEntrypointUrl] = useState('');

  const fetchEntrypoints = async () => {
    const entrypointData = await getEntrypoints();
    setEntrypoints(entrypointData);
  };

  useEffect(() => {
    fetchEntrypoints();
  }, [reloadState]);

  useEffect(() => {
    if (entrypoints) {
      setEntrypointsAdaptedForCardGrid(
        mapFetchedEntrypointsDataFetchedToCardGridCompatibleData(
          entrypoints.Items
        )
      );
    }
  }, [entrypoints]);

  const mapFetchedEntrypointsDataFetchedToCardGridCompatibleData = (
    entrypoints: EntrypointData[]
  ): EntrypointContent[] =>
    entrypoints.map((entrypoint) => {
      return {
        title:
          entrypoint.ReportMetaData?.name ??
          entrypoint.EntrypointId.substring(0, 8),
        url: entrypoint.Url,
        id: entrypoint.EntrypointId,
        remainingUses: entrypoint.RemainingUses,
        expiresAt: entrypoint.ExpiresAt
          ? new Date(entrypoint.ExpiresAt.replace('Z', ''))
          : undefined
      };
    });

  const onDeleteEntrypoint = async (entrypointId: string) => {
    try {
      await deleteEntrypoint(entrypointId);
      setReloadState(entrypointId);
    } catch (e) {
      console.log('Delete entrypoint errors');
      throw e;
    }
  };

  const handleShowCreateModal = () => {
    setShowCreateModal(true);
  };

  const handleHideCreateModal = () => {
    setShowCreateModal(false);
  };

  const handleCloseMetaDataModal = () => {
    setCopyableEntrypointUrl('');
  };

  return (
    <div className={styles.entrypoints}>
      <h1 className={styles.title}>Entrypoints</h1>

      {entrypointsAdaptedForCardGrid && (
        <EntrypointTable
          tableContent={entrypointsAdaptedForCardGrid}
          onDeleteEntrypoint={onDeleteEntrypoint}
          onCreateEntrypoint={handleShowCreateModal}
          onCopyEntrypoint={setCopyableEntrypointUrl}
        />
      )}

      {showCreateModal && (
        <CreateEntryPointModal
          closeModal={handleHideCreateModal}
          fetchEntryPoints={fetchEntrypoints}
        />
      )}

      {!!copyableEntrypointUrl && (
        <AddMetaDataModal
          url={copyableEntrypointUrl}
          closeModal={handleCloseMetaDataModal}
        />
      )}
    </div>
  );
};
