import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

const selectSelf = (state: RootState) => state.user;

export const isSignedInSelector = createSelector(selectSelf, (state) =>
  Boolean(state.profile)
);

export const userProfileSelector = createSelector(
  selectSelf,
  (state) => state.profile
);
