import { Routes, Route } from 'react-router-dom';
import { routes } from './common/routes';
import { AppRoute } from './common/types';
import { Amplify } from 'aws-amplify';
import amplifyConfig from './amplify-config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

Amplify.configure(amplifyConfig);

function renderRoute(_props: AppRoute) {
  const { routes, ...props } = _props;
  const children = routes?.map((route) => renderRoute(route)) ?? <></>;

  return <Route {...props}>{children}</Route>;
}

function App() {
  return (
    <div>
      <Routes>
        {routes.map((route) => {
          return renderRoute(route);
        })}
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
