export const maxEntrypointDate = new Date(new Date().getTime() + 2147483647000);

export function formatDateUK(date: Date): string {
  return date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
}

export function sortDate(
  a: Date | undefined,
  b: Date | undefined,
  asc: boolean
): number {
  if (a && b) {
    if (a < b) return asc ? -1 : 1;
    if (a > b) return asc ? 1 : -1;
  }
  return 0;
}
