import React from 'react';
import { createEntrypoint } from './entrypoint-create-api';
import { toast } from 'react-toastify';
import { ErrorMessage, Form, Formik } from 'formik';
import { entrypointCreateValidator } from './entrypoint-create-validator';
import ArrowBackgroundImage from '@/assets/image/arrow_background.png';
import { Button, Input } from '@/components';
import styles from './style.module.css';
import { EntrypointCreateData } from '@/components/Modal/CreateEntryPointModal/entrypoint-create-types';

type Props = {
  closeModal: () => void;
  fetchEntryPoints: () => void;
};

export const CreateEntryPointModal: React.FC<Props> = ({
  closeModal,
  fetchEntryPoints
}) => {
  const initialValues: EntrypointCreateData = {
    redirectUrl: '',
    uses: undefined,
    expiry: 0,
    name: ''
  };

  const onSubmit: React.ComponentProps<
    typeof Formik<typeof initialValues>
  >['onSubmit'] = async (values, { setSubmitting, resetForm }) => {
    try {
      const todaySecond = Math.ceil(new Date().getTime() / 1000);
      const expirySecond = Math.ceil(new Date(values.expiry).getTime() / 1000);
      setSubmitting(true);
      await createEntrypoint({
        ...values,
        expiry: expirySecond - todaySecond
      });
      fetchEntryPoints();
      toast.success('Create new entrypoint successfully');
      resetForm();
      closeModal();
    } catch (e: any) {
      toast.error(e?.message || 'There was an error creating new entrypoint');
      //TODO
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div className="rounded-sm shadow-lg bg-white max-w-[780px] h-[610px] w-full">
          <div className="flex justify-end pt-5 px-6 mb-2.5">
            <span
              className="leading-4 text-black text-3xl cursor-pointer"
              onClick={closeModal}
            >
              ×
            </span>
          </div>
          <div className="flex pl-[80px]">
            <div className="max-w-[266px] w-full">
              <h2 className="text-lg mb-4">Create Entrypoint</h2>
              <Formik
                initialValues={initialValues}
                validationSchema={entrypointCreateValidator}
                onSubmit={onSubmit}
              >
                {({ values, handleChange, isSubmitting }) => (
                  <Form>
                    <div className="form-group mb-4">
                      <Input
                        className={styles.input}
                        onChange={handleChange}
                        value={values.redirectUrl}
                        id="redirectUrl"
                        name="redirectUrl"
                        placeholder="Redirect URL (*)"
                      />
                      <ErrorMessage
                        component="a"
                        className="text-danger"
                        name="redirectUrl"
                      />
                    </div>

                    <div className="form-group mb-4">
                      <Input
                        className={styles.input}
                        value={values.uses}
                        onChange={handleChange}
                        name="uses"
                        id="uses"
                        type="number"
                        placeholder="Uses (*)"
                      />
                      <ErrorMessage
                        component="a"
                        className="text-danger"
                        name="uses"
                      />
                    </div>

                    <div className="form-group mb-4 relative">
                      <span className="bg-white text-[10px] absolute -top-2 left-1.5 px-1 text-gray-400">
                        Expiry Time (*)
                      </span>
                      <Input
                        className={styles.input}
                        value={values.expiry}
                        onChange={handleChange}
                        name="expiry"
                        type="datetime-local"
                        id="expiry"
                      />
                      <ErrorMessage
                        component="a"
                        className="text-danger"
                        name="expiry"
                      />
                    </div>

                    <div className="form-group mb-8">
                      <Input
                        className={styles.input}
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Entrypoint name"
                        name="name"
                        type="text"
                        id="name"
                      />
                      <ErrorMessage
                        component="a"
                        className="text-danger"
                        name="name"
                      />
                    </div>
                    <div className="">
                      <Button
                        className={styles.submitButton}
                        disabled={isSubmitting}
                        theme="primary"
                        type="submit"
                      >
                        Submit
                      </Button>
                      <Button
                        className={styles.cancelButton}
                        type="button"
                        disabled={isSubmitting}
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="flex-1 flex items-center justify-center pl-[80px]">
              <img
                src={ArrowBackgroundImage}
                width={105}
                height={248}
                alt="arrow-bg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
};
