import {
  Outlet,
  OutletProps,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { useAppDispatch } from '@/store';
import { getUserProfile } from '@/store/user';
import { useEffect, useState } from 'react';
import { Header, Breadcrumb } from '@/containers';

const DefaultLayout = (props: OutletProps) => {
  const dp = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isProfileLoaded, setProfileLoadedState] = useState(false);

  const navigateToSignInPage = (): void => {
    const destination = `${location.pathname}${location.search}`;
    return navigate('/sign-in', {
      state: {
        destination
      }
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const { cognitoUser, token } = await dp(getUserProfile()).unwrap();

        if (!cognitoUser || !token) navigateToSignInPage();

        setProfileLoadedState(true);
      } catch (e) {
        navigateToSignInPage();
      }
    })();
  }, []);

  if (!isProfileLoaded) return null;

  return (
    <>
      <Header />
      <Breadcrumb />

      <div className="container py-5">
        <Outlet {...props}></Outlet>
      </div>
    </>
  );
};

export default DefaultLayout;
