import React, { ChangeEvent, useMemo, useState } from 'react';
import { CardGrid } from '@/components/CardGrid/CardGrid';
import { getReport } from '~/Sessions/sessions-api';
import { toast } from 'react-toastify';
import { generateDocumentDefinitionFromJSON } from '@/utils/pdf';
import format from 'date-fns/format';
import { IModal, Modal } from '@/components/Modal';
import pdfMake, { createPdf } from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { reactAppConfig } from '@/config/react-app-config';
import { ReactComponent as SearchIcon } from '@/assets/image/icons/search.svg';
import { ReactComponent as RecycleIcon } from '@/assets/image/icons/recycle.svg';
import { Button } from '@/components';
import { sortDate } from '@/utils/date';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const fonts = {
  Apotek: {
    bold: `${reactAppConfig.frontendUrl}/fonts/Apotek/Apotek-Cond-Bold.ttf`,
    normal: `${reactAppConfig.frontendUrl}/fonts/Apotek/Apotek-Cond-Medium.ttf`
  },
  Roboto: {
    normal: `${reactAppConfig.frontendUrl}/fonts/Roboto/Roboto-Regular.ttf`,
    bold: `${reactAppConfig.frontendUrl}/fonts/Roboto/Roboto-Medium.ttf`,
    italics: `${reactAppConfig.frontendUrl}/fonts/Roboto/Roboto-Italic.ttf`,
    bolditalics: `${reactAppConfig.frontendUrl}/fonts/Roboto/Roboto-MediumItalic.ttf`
  }
};

export interface SessionsCardGridProps {
  gridContent: SessionCardContent[];
  parentId: string;
  // eslint-disable-next-line no-unused-vars
  onDeleteSession: (sessionId: string) => Promise<void>;
  onRefresh: () => void;
}

export interface SessionCardContent {
  id: string;
  title: string;
  subtitle: string;
  inProgress: boolean;
  metadata: Record<string, string>;
  closedAt: Date | undefined;
  createdAt: Date | undefined;
  shouldHighlight: boolean;
}

export const SessionCardGrid = (
  sessionsCardGridProps: SessionsCardGridProps
) => {
  const {
    parentId: entrypointId,
    onDeleteSession,
    onRefresh
  } = sessionsCardGridProps;

  const [deleteModal, setDeleteModal] = useState<IModal>({
    showModal: false,
    title: 'Delete Session?',
    description: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    action: async () => {}
  });

  const [titleOrMetadataForSearch, setTitleOrMetadataForSearch] = useState('');

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setTitleOrMetadataForSearch(event.target.value);
  };

  const handleDeleteSession = (cardContent) => {
    setDeleteModal({
      ...deleteModal,
      showModal: true,
      description: `Are you sure that you want to delete session ${cardContent.title}?`,
      action: async () => {
        try {
          await onDeleteSession(cardContent.id || '');
          toast.success('Deleted!');
        } catch (e: any) {
          toast.error(e?.message || 'There was an error deleting session');
        } finally {
          setDeleteModal({
            ...deleteModal,
            showModal: false
          });
        }
      }
    });
  };

  const handleDownloadSession = (cardContent) => {
    const getReportData = async () => {
      try {
        const reportData = await getReport(entrypointId, cardContent.id!);

        if (!reportData?.Items?.length) {
          toast.warning('There are no reports available for this session');
        } else {
          const dateStr = cardContent.closedAt
            ? format(cardContent.closedAt, 'yyyy-MM-dd-HH-ii')
            : 'UnknownTime';
          const documentTitle = `${dateStr}-${cardContent.title}.pdf`;

          createPdf(
            generateDocumentDefinitionFromJSON(
              reportData.Items.map((report) => report.Payload),
              {
                info: {
                  title: documentTitle
                }
              },
              cardContent.metadata
            ),
            {},
            fonts
          ).download(documentTitle);
        }
      } catch (error) {
        toast.error('There was an error fetching your reports');
      }
    };
    getReportData();
  };

  const filteredContent = useMemo(() => {
    return sessionsCardGridProps.gridContent
      .filter((item) => {
        const doesSessionExistByTitleSearch =
          item.title
            .toLowerCase()
            .indexOf(titleOrMetadataForSearch.toLowerCase().trim()) > -1;

        let doesSessionExistByMetadata = false;
        if (item.metadata) {
          Object.values(item.metadata).forEach((metadataItemValue) => {
            if (
              metadataItemValue
                .toLowerCase()
                .includes(titleOrMetadataForSearch.toLowerCase().trim())
            )
              doesSessionExistByMetadata = true;
          });
        }

        return doesSessionExistByTitleSearch || doesSessionExistByMetadata;
      })
      .sort((a, b) => sortDate(a.createdAt, b.createdAt, false));
  }, [sessionsCardGridProps.gridContent, titleOrMetadataForSearch]);

  return (
    <>
      <Modal modal={deleteModal} setModal={setDeleteModal} />

      <div className="flex mb-2.5">
        <div className="flex flex-1 justify-center">
          <div className="flex items-center border-b border-b-gray-300 max-w-[270px] h-[28px] w-full">
            <SearchIcon className="mr-1.5" />
            <input
              className="border-0 focus:border-0 focus:outline-0 flex-1"
              placeholder="Search by title or metadata"
              value={titleOrMetadataForSearch}
              onChange={handleChangeSearch}
            />
          </div>
        </div>
        <Button
          className="flex items-center justify-center h-6 w-[90px] text-white rounded-md bg-apari-purple hover:bg-blue-800"
          onClick={onRefresh}
        >
          <RecycleIcon
            className="mr-1 flex-shrink-0"
            width={11.5}
            height={11.5}
          />
          <span className="text-[13px] font-semibold ml-1">Refresh</span>
        </Button>
      </div>

      <CardGrid<SessionCardContent>
        gridContent={filteredContent}
        onDelete={handleDeleteSession}
        onDownload={handleDownloadSession}
      />
    </>
  );
};
